import React, { useEffect, useState } from 'react'
import Button from "../../extras/Button";
import Input, { ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { editData, objectToFormData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { DangerRight } from '../../api/toastServices';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { rescheduleBooking } from '../../../redux/slice/bookingSlice';

const BookingDialogue = () => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);

    const [c_fname, setCFname] = useState("");
    const [c_lname, setCLname] = useState("");
    const [c_email, setCEmail] = useState("");
    const [c_mobile, setCMobile] = useState("");

    const [s_fname, setSFname] = useState("");
    const [s_lname, setSLname] = useState("");
    const [s_email, setSEmail] = useState("");
    const [s_mobile, setSMobile] = useState("");

    const [startTime, setStartTime] = useState();
    const [date, setDate] = useState();


    useEffect(() => {
        if (dialogueData) {
            // console.log(dialogueData);
            editData(dialogueData);
            setCFname(dialogueData?.user?.fname);
            setCLname(dialogueData?.user?.lname);
            setCEmail(dialogueData?.user?.email);
            setCMobile(dialogueData?.user?.mobile);

            setSFname(dialogueData?.expert?.fname);
            setSLname(dialogueData?.expert?.lname);
            setSEmail(dialogueData?.expert?.email);
            setSMobile(dialogueData?.expert?.mobile);

            setStartTime(moment(dialogueData?.startTime, "hh:mm A").format("HH:mm"));
            setDate(dialogueData?.date);
        }
    }, [dialogueData]);
    const handleSubmit = async (e) => {
        // console.log(e);
        e.preventDefault();

        const formDataJson = {
            startTime: moment(startTime, "HH:mm").format("hh:mm A"),
            date: date,
            bookingId: dialogueData.id,
            _method: 'post',
        };
        // console.log('start time: ', startTime);
        // console.log('date: ', date);
        // console.log(formDataJson);
        try {
            // let response;
            dispatch(rescheduleBooking(formDataJson))
                .then((res) => {
                    console.log(res);
                    if (res.payload.status) {
                        dispatch(closeDialog());
                    } else {
                        DangerRight(res.message);
                    }
                })
                .catch((err) => {
                    console.error("Error:", err);
                    DangerRight(err.message || "Something went wrong");
                });
        } catch (err) {
            console.log("err", err);
            DangerRight(err.message);
        }

    };


    return (

        <div className="dialog">
            <div class="w-100">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-md-8 col-11">
                        <div class="mainDiaogBox">
                            <div class="row justify-content-between align-items-center formHead">
                                <div className="col-8">
                                    <h2 className="text-theme m0">Booking Dialogue</h2>
                                </div>
                                <div className="col-4">
                                    <div
                                        className="closeButton"
                                        onClick={() => {
                                            dispatch(closeDialog());
                                        }}
                                    >
                                        <i className="ri-close-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-start formBody">
                                <hr ></hr>
                                <h4 >Client Info: </h4>
                                <div className="col-12 col-md-6">
                                    <p><strong>Name:</strong> {c_fname + ' ' + c_lname}</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <p><strong>Email</strong> {c_email}</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <p><strong>Mobile</strong> {c_mobile}</p>
                                </div>
                                <hr></hr>
                                <h4>Staff Info: </h4>
                                <div className="col-12 col-md-6">
                                    <p><strong>Name:</strong> {s_fname + ' ' + s_lname}</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <p><strong>Email</strong> {s_email}</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <p><strong>Mobile</strong> {s_mobile}</p>
                                </div>
                                <hr></hr>
                                <h4>Booking Info: </h4>
                                <div className="col-12">
                                    <p><strong>Status:</strong> {dialogueData?.status}</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="date" className="mb-1">Date</label>
                                    <br></br>
                                    <DatePicker
                                        id="date"
                                        name="date"
                                        selected={date ? new Date(date) : null}
                                        onChange={(date) => setDate(date ? date.toISOString().split("T")[0] : "")}
                                        className="form-control w-full"
                                        placeholderText="Select Date"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <ExInput
                                        type={`time`}
                                        value={startTime}
                                        id={`startTime`}
                                        name={`startTime`}
                                        label={`Start Time`}
                                        placeholder={`Start Time`}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setStartTime(e.target.value);
                                            console.log(startTime);
                                        }}
                                    />
                                </div>


                            </div>
                            <div className="row  formFooter">
                                <div className="col-12 text-end m0">
                                    <Button className={`bg-gray text-light`} text={`Cancel`} type={`button`} onClick={() => dispatch(closeDialog())} />
                                    <Button
                                        type={`submit`}
                                        className={`bg-theme text-light m10-left`}
                                        text={`Submit`}
                                        onClick={(e) => handleSubmit(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingDialogue;
