import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstanceFetch } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";

const initialState = {
    booking: [],
    calendarData: [],
    futureBooking: [],
    isLoading: false,
    isSkeleton: false,
    total: null
}

export const addBooking = createAsyncThunk("user/booking", async (payload) => {

    return apiInstanceFetch.post(`user/booking`, payload);
})

export const getAllBookings = createAsyncThunk("user/booking/getAll", async (payload) => {

    return apiInstanceFetch.get(`user/booking/getAll?start=${payload?.start}&limit=${payload?.limit}&type=${payload?.type}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`)
})

export const upcomingBookings = createAsyncThunk("user/booking/upcomingBookings", async (type) => {
    return apiInstanceFetch.get(`user/booking/upcomingBookings?type=${type}`)
})

export const dailyBookings = createAsyncThunk("user/booking/dailyBookings", async (payload) => {
    return apiInstanceFetch.get(`user/booking/dailyBookings?start=${payload?.start}&limit=${payload?.limit}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`)
})

export const cancelBooking = createAsyncThunk("user/booking/updateBookingStatus", async (payload) => {
    return apiInstanceFetch.post(`user/booking/updateBookingStatus`, payload)
})

export const rescheduleBooking = createAsyncThunk("user/booking/updateBookingSchedule", async (payload) => {
    return apiInstanceFetch.post(`user/booking/updateBookingSchedule`, payload)
})

export const getAllBookingsCalendar = createAsyncThunk("user/booking/calenderView", async (payload) => {
    return apiInstanceFetch.get(`user/booking/calenderView`)
})

const bookingSlice = createSlice({
    name: "bookingSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addBooking.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(addBooking.fulfilled, (state, action) => {
            // console.log(action)
            if (action.payload.status) {
                state.isSkeleton = false;
                state.booking.unshift(action.payload?.savedBooking);
            }
            state.isLoading = false;
        })

        builder.addCase(addBooking.rejected, (state, action) => {
            // DangerRight(action.payload.message);
            state.isLoading = false;
        })

        builder.addCase(getAllBookings.pending, (state, action) => {
            state.isSkeleton = true;
        })

        builder.addCase(getAllBookings.fulfilled, (state, action) => {

            state.isSkeleton = false;
            state.booking = action.payload.bookings;
            state.total = action.payload.total
        })

        builder.addCase(getAllBookings.rejected, (state, action) => {

            state.isSkeleton = false;
        })



        builder.addCase(getAllBookingsCalendar.pending, (state, action) => {
            state.isSkeleton = true;
        })

        builder.addCase(getAllBookingsCalendar.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.calendarData = action.payload.services;
        })

        builder.addCase(getAllBookingsCalendar.rejected, (state, action) => {

            state.isSkeleton = false;
        })

        builder.addCase(dailyBookings.pending, (state, action) => {
            state.isSkeleton = true;
        })

        builder.addCase(dailyBookings.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.booking = action.payload.data;
            state.total = action.payload.total
        })

        builder.addCase(dailyBookings.rejected, (state, action) => {
            state.isSkeleton = false;
        })

        builder.addCase(upcomingBookings.pending, (state, action) => {
            state.isSkeleton = true;
        })

        builder.addCase(upcomingBookings.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.futureBooking = action.payload.data;
        })

        builder.addCase(upcomingBookings.rejected, (state, action) => {
            state.isSkeleton = false;
        })

        builder.addCase(cancelBooking.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(cancelBooking.fulfilled, (state, action) => {
            if (action.payload.status) {
                // console.log('hello');
                const bookingIndex = state?.booking?.findIndex((booking) => booking?.id === action?.payload?.booking?.id);
                if (bookingIndex !== -1) {
                    state.booking[bookingIndex] = { ...state?.booking[bookingIndex], ...action?.payload?.booking };
                }
                Success("Booking Cancel Successfully")
            }
            state.isLoading = false;
        })

        builder.addCase(cancelBooking.rejected, (state, action) => {
            state.isLoading = false;
        })

        builder.addCase(rescheduleBooking.fulfilled, (state, action) => {
            if (action.payload.status) {
                // console.log('hello');
                const bookingIndex = state?.booking?.findIndex((booking) => booking?.id === action?.payload?.booking?.id);
                if (bookingIndex !== -1) {
                    state.booking[bookingIndex] = { ...state?.booking[bookingIndex], ...action?.payload?.booking };
                }
                Success("Booking Rescheduled Successfully");
            }
            state.isLoading = false;
        })
    }
})
export default bookingSlice.reducer;