import React, { useEffect, useState } from 'react'
import Button from "../../extras/Button";
import Input, { ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { DangerRight, Success } from '../../api/toastServices';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllServices } from '../../../redux/slice/serviceSlice';
import { getAllExpert } from '../../../redux/slice/expertSlice';
import { Autocomplete, TextField } from '@mui/material';
import { allUsers } from '../../../redux/slice/userSlice';
import { addBooking } from '../../../redux/slice/bookingSlice';
import { navigate } from 'react-big-calendar/lib/utils/constants';

const BookingAddDialogue = () => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);

    const [allService, setAllService] = useState([]);
    const [allStaff, setAllStaff] = useState([]);
    const [allClients, setAllClients] = useState([]);

    const [staff, setStaff] = useState("");
    const [client, setClient] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [startTime, setStartTime] = useState();
    const [date, setDate] = useState();

    // const serviceList = service?.map((list) => ({
    //     name: list?.name,
    //     id: list?.id,
    // }));

    useEffect(() => {
        dispatch(getAllExpert({
            start: 0,
            limit: 0,
            search: 'ALL',
        })).then((res) => {
            console.log(res.payload);
            if (res.payload.status) {
                setAllStaff(res.payload.experts)
            }
        });

        dispatch(allUsers({
            start: 0,
            limit: 0,
            search: 'ALL',
        })).then((res) => {
            console.log(res.payload);
            if (res.payload.status) {
                setAllClients(res.payload.users);
            }
        });
    }, []);

    const [error, setError] = useState({
        client: "",
        expert: "",
        services: "",
        date: "",
        time: "",
    });
    const handleSubmit = async (e) => {
        if (
            !client ||
            !staff ||
            !serviceList?.length ||
            !date ||
            !startTime
        ) {
            let error = {};
            if (!client) error.client = "client is required";
            if (!staff) error.expert = "staff member is required";

            if (!date) error.date = "date is required";
            if (!startTime) error.time = "Start time is required";
            if (!serviceList?.length)
                error.services = "At least one service must be selected";
            return setError({ ...error });
        } else {
            // const formData = new FormData();
            // formData.append("userId", client.id);
            // formData.append("expertId", staff.id);
            // formData.append("date", date);
            // formData.append("startTime", startTime);
            const serviceIds = serviceList?.map((service) => service.id)?.join(",");
            // formData.append("serviceId", serviceIds);
            const formDataJson = {
                time: moment(startTime, "HH:mm").format("HH:mm:ss"),
                date: date,
                expertId: staff.id,
                userId: client.id,
                serviceId: serviceIds,
                _method: 'post',
                paymentType: 0,
            };
            // console.log(formDataJson);
            // try {
            dispatch(addBooking(formDataJson)).then((res) => {
                console.log(res);
                if (res?.payload?.status) {
                    Success(res?.payload?.message);
                    dispatch(closeDialog());
                    // navigate('/')
                } else {
                    DangerRight(res.error.message);
                }
            })
            // } 
        }
    };


    return (

        <div className="dialog">
            <div class="w-100">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-md-8 col-11">
                        <div class="mainDiaogBox">
                            <div class="row justify-content-between align-items-center formHead">
                                <div className="col-8">
                                    <h2 className="text-theme m0">Add New Booking</h2>
                                </div>


                                <div className="col-4">
                                    <div
                                        className="closeButton"
                                        onClick={() => {
                                            dispatch(closeDialog());
                                        }}
                                    >
                                        <i className="ri-close-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-start formBody">
                                <hr ></hr>
                                <h4 >Client Info: </h4>
                                <div className="col-12">
                                    <Autocomplete
                                        value={client ?? []}
                                        disablePortal
                                        id="tags-standard"
                                        options={allClients || []}
                                        getOptionLabel={(option) => option.email}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(e, value) => {
                                            setClient(value);
                                            if (!value) {
                                                return setError({
                                                    ...error,
                                                    client: "client is required",
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    client: null,
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Client"
                                            />
                                        )}
                                        fullWidth
                                    />
                                    {error.client && (
                                        <p className="errorMessage">{error?.client}</p>
                                    )}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row align-items-start formBody">
                                <h4>Staff Info: </h4>
                                <div className="col-12 col-md-6">
                                    <Autocomplete
                                        value={staff ?? []}
                                        disablePortal
                                        id="tags-standard"
                                        options={allStaff || []}
                                        getOptionLabel={(option) => option.email}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(e, value) => {
                                            setStaff(value);
                                            setAllService(value?.services);
                                            setServiceList([]);
                                            if (!value) {
                                                return setError({
                                                    ...error,
                                                    expert: "staff is required",
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    expert: null,
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Staff"
                                            />
                                        )}
                                        fullWidth
                                    />
                                    {error.expert && (
                                        <p className="errorMessage">{error?.expert}</p>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <Autocomplete
                                        value={serviceList ?? []}
                                        multiple
                                        disablePortal
                                        id="tags-standard"
                                        options={allService || []}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(e, value) => {
                                            setServiceList(value);
                                            if (!value) {
                                                return setError({
                                                    ...error,
                                                    services: "At least one service must be selected",
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    services: null,
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Services"
                                            />
                                        )}
                                        fullWidth
                                    />
                                    {error.services && (
                                        <p className="errorMessage">{error?.services}</p>
                                    )}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row align-items-start formBody">

                                <h4>Booking Info: </h4>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="date" className="mb-1">Date</label>
                                    <br></br>
                                    <DatePicker
                                        id="date"
                                        name="date"
                                        selected={date ? new Date(date) : null}
                                        onChange={(date) => {
                                            setDate(date ? date.toISOString().split("T")[0] : "")
                                            if (!date) {
                                                return setError({
                                                    ...error,
                                                    date: "date is required",
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    date: null,
                                                });
                                            }
                                        }}
                                        className="form-control w-full"
                                        placeholderText="Select Date"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {error.date && (
                                        <p className="errorMessage">{error?.date}</p>
                                    )}
                                </div>

                                <div className="col-12 col-md-6">
                                    <ExInput
                                        type={`time`}
                                        value={startTime}
                                        id={`startTime`}
                                        name={`startTime`}
                                        label={`Start Time`}
                                        placeholder={`Start Time`}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setStartTime(e.target.value);
                                            // console.log(startTime);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...error,
                                                    time: "start time is required",
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    time: null,
                                                });
                                            }
                                        }}
                                    />
                                    {error.time && (
                                        <p className="errorMessage">{error?.time}</p>
                                    )}
                                </div>
                            </div>

                            <div className="row  formFooter">
                                <div className="col-12 text-end m0">
                                    <Button className={`bg-gray text-light`} text={`Cancel`} type={`button`} onClick={() => dispatch(closeDialog())} />
                                    <Button
                                        type={`submit`}
                                        className={`bg-theme text-light m10-left`}
                                        text={`Submit`}
                                        onClick={(e) => handleSubmit(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingAddDialogue;
