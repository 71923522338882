/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import Input, { Image, ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { expertAdd, expertUpdate } from "../../../redux/slice/expertSlice";
import { addExpert, updateExpert } from "../../../redux/api";
import Multiselect from "multiselect-react-dropdown";
import { getAllServices } from "../../../redux/slice/serviceSlice";
import { Autocomplete, TextField } from "@mui/material";
import { imageURL } from "../../../util/config";
import { addUser } from "../../../redux/slice/userSlice";


export const UserDialogue = ({ data, setData }) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [age, setAge] = useState();
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [email, setEmail] = useState("");
  const [mongoId, setMongoId] = useState();
  const [bio, setBio] = useState("");
  const [password, setPassword] = useState("");
  const [identity, setIdentity] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?.id);
      setFname(dialogueData?.fname);
      setLname(dialogueData?.lname);
      setImagePath(dialogueData?.image);
      setEmail(dialogueData?.email);
      setPassword("");
      setAge(dialogueData?.age);
      setMobile(dialogueData?.mobile);
      setGender(dialogueData?.gender);
      setBio(dialogueData?.bio);
      setIdentity(dialogueData?.identity);
    }
  }, [dialogueData]);

  const [error, setError] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    image: "",
    gender: "",
    age: "",
    bio: "",
    password: "",
    identity: "",
  });

  const isEmailValid = /\S+@\S+\.\S+/.test(email);
  const handleSubmit = async (e) => {

    if (
      !lname ||
      !fname ||
      !age ||
      !email ||
      !password ||
      !mobile ||
      !gender ||
      !image ||
      !identity
    ) {
      let error = {};
      if (!lname) error.lname = "Last name is required";
      if (!fname) error.fname = "First name is required";
      if (!image?.length === 0 || !imagePath)
        error.image = "Image is required!";
      if (!age) error.age = "age is required";
      if (!mobile) error.mobile = "mobile number is required";
      if (!email) error.email = "email is required";
      if (!password) error.password = "password is required";
      if (!identity) error.identity = "identity is required";
      if (mobile.length < 6 || mobile.length > 13)
        error.mobile = "Mobile number must be 6 to 13 digits";
      if (age < 18 || age > 100) error.age = "Invalid Age";
      return setError({ ...error });
    } else if (!isEmailValid) {
      const error = { email: "Email address is invalid" };
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("lname", lname);
      formData.append("fname", fname);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("age", age);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("password", password);
      formData.append("identity", identity);
      formData.append("loginType", 1);
      if (mongoId) {
        formData.append('_method', 'POST');

        const payload = { formData, expert_id: mongoId };
        dispatch(expertUpdate(payload));
      } else {
        if (!password) {
          let error = {};
          error.password = "password is required";
          return setError({ ...error });
        }
        dispatch(addUser(formData));
      }
      dispatch(closeDialog());
    }
  };
  useEffect(() => {
    dispatch(getAllServices({ start: 0, limit: 100, search: "ALL" }));
  }, []);

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);

      const localImageURL = URL.createObjectURL(file);

      const imgElement = document.getElementById("image-src");
      if (imgElement) {
        imgElement.src = localImageURL;
      }
    }

    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">Customer Dialogue</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>

              <div className="row align-items-start formBody">
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    id={`fname`}
                    name={`fname`}
                    value={fname}
                    label={`First name`}
                    placeholder={`First name`}
                    errorMessage={error.fname && error.fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          fname: `First Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          fname: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    value={lname}
                    id={`lname`}
                    name={`lname`}
                    label={`Last Name`}
                    placeholder={`Last Name`}
                    errorMessage={error.lname && error.lname}
                    onChange={(e) => {
                      setLname(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          lname: `Last Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          lname: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`number`}
                    value={mobile}
                    id={`mono`}
                    name={`mobile`}
                    label={`Mobile Number`}
                    minLength={6}
                    maxLength={13}
                    placeholder={`Mobile Number`}
                    errorMessage={error.mobile && error.mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          mobile: `Mobile Number Is Required`,
                        });
                      } else if (
                        e.target.value.length < 6 ||
                        e.target.value.length > 13
                      ) {
                        return setError({
                          ...error,
                          mobile: "Mobile number must be 6 to 13 digits",
                        });
                      } else {
                        return setError({
                          ...error,
                          mobile: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    id={`email`}
                    name={`email`}
                    label={`Email`}
                    value={email}
                    placeholder={`Email`}
                    errorMessage={error.email && error.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `email Id is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`number`}
                    id={`age`}
                    name={`age`}
                    value={age}
                    label={`Age`}
                    placeholder={`Age`}
                    minLength={2}
                    maxLength={2}
                    errorMessage={error.age && error.age}
                    onChange={(e) => {
                      setAge(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          age: `age is Required`,
                        });
                      } else if (
                        e.target.value.length < 2 ||
                        e.target.value.length > 3
                      ) {
                        return setError({
                          ...error,
                          age: "Invalid Age",
                        });
                      } else {
                        return setError({
                          ...error,
                          age: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    id={`identity`}
                    name={`identity`}
                    value={identity}
                    label={`Identity`}
                    placeholder={`Identity`}
                    errorMessage={error.identity && error.identity}
                    onChange={(e) => {
                      setIdentity(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          identity: `identity is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          identity: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-12">
                  <ExInput
                    type={`password`}
                    id={`password`}
                    name={`password`}
                    value={password}
                    label={`Password`}
                    placeholder={`Password`}
                    errorMessage={error.password && error.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          password: `password is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          password: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-12">
                  <label htmlFor="Bio">Bio</label>
                  <textarea
                    id="Bio"
                    name="Bio"
                    value={bio}
                    placeholder="Enter Bio"
                    className="form-control"
                    onChange={(e) => {
                      setBio(e.target.value);
                      if (!e.target.value.trim()) {
                        return setError({
                          ...error,
                          bio: "Bio is required",
                        });
                      } else {
                        return setError({
                          ...error,
                          bio: "",
                        });
                      }
                    }}
                  />
                  {error.bio && <small className="text-danger">{error.bio}</small>}
                </div>


                <div className="d-flex justify-content-between col-12 col-md-6 mt-md-5 mt-sm-0">
                  <span className="fs-16 fw-600"> Gender: </span>
                  <div className="col-4 ms-2">
                    <ExInput
                      type={`radio`}
                      id={`male`}
                      label={`Male`}
                      name={`gender`}
                      value={`male`}
                      checked={(gender === "male" || gender === "Male") && true}
                      onChange={(e) => {
                        setGender(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            gender: `Gender Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            gender: "",
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-4">
                    <ExInput
                      type={`radio`}
                      id={`female`}
                      label={`Female`}
                      name={`gender`}
                      value={`female`}
                      checked={
                        (gender === "female" || gender === "Female") && true
                      }
                      onChange={(e) => {
                        setGender(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            gender: `Gender Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            gender: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <ExInput
                    label={`Image`}
                    id={`image`}
                    type={`file`}
                    onChange={(e) => handleImage(e)}
                    errorMessage={error.image && error.image}
                    accept={"image/*"}
                  />
                  <img
                    src={imagePath !== "" ? `${imageURL}/${imagePath}` : null}
                    alt=""
                    id="image-src"
                    draggable="false"
                    className={`${(!imagePath || imagePath === "") && "d-none"
                      } `}
                    data-class={`showImage`}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </div>

              <div className="row  formFooter">
                <div className="col-12 text-end m0">
                  <Button
                    className={`bg-gray text-light`}
                    text={`Cancel`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`bg-theme text-light m10-left`}
                    text={`Submit`}
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
